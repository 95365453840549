<template>
  <div>
    <div class="infotDetai">
      <p class="title">【{{ title }}】 {{ dataObj.title }}</p>
      <p class="content">
        <!--        <span class="time">{{ dataObj.source_dictText }}</span>-->
        <span class="time" style="margin-top: 5px; display: block">{{ dataObj.createTime || dataObj.updateTime }}</span>
        <!-- <span class="sousang" @click="switchClick(dataObj.inCollect)">
          <img :src="dataObj.inCollect | inCollectImg" />
        </span> -->
      </p>
      <div class="edit" v-html="dataObj.content"></div>
      <div class="edirct" style="margin-top:40px;"  >
        <div style="font-size:16px;font-weight: bold;margin-bottom:10px;" v-if="file.length>0">附件：</div>
          <a class="fileUrl" :href="i.url" v-for="(i, index) in file" :key="index">
            <p style="margin-bottom:10px;color:#004E98" >{{ i.name }}</p>
          </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { articleDetail, articleDetailWithToken } from '@/api/news'
import { actionsSwitch } from '@/api/public'
import { Toast, Indicator } from 'mint-ui'
import { getCookie } from '@/utils/domUtil'
import { ACCESS_TOKEN } from '@/store/mutation-types'

const collectionYellow = require('@/assets/img/information/icon_collection_yellow.png')
const collectionNor = require('@/assets/img/information/icon_collection_nor.png')

export default {
  data() {
    return {
      dataObj: {},
      id: '',
      title: '',
      file: []
    }
  },
  filters: {
    // 关注状态显示
    inCollectImg(val) {
      if (val) return collectionYellow
      return collectionNor
    }
  },
  computed: {
    ...mapGetters(['token'])
  },
  created() {
    this.id = this.$route.query.id
    this.title = this.$route.query.title
    this.article()
  },
  methods: {
    switchClick(val) {
      const token = getCookie(ACCESS_TOKEN)
      if (token) {
        if (!val) {
          actionsSwitch({
            collectId: this.id,
            type: 2,
            optType: 'save'
          })
            .then((res) => {
              this.dataObj.inCollect = true
            })
            .catch((err) => {
              this.requestFailed(err)
            })
        } else {
          actionsSwitch({
            collectId: this.id,
            type: 2,
            optType: 'cancel'
          })
            .then((res) => {
              this.dataObj.inCollect = false
            })
            .catch((err) => {
              this.requestFailed(err)
            })
        }
      } else {
        this.$router.push({
          name: 'login'
        })
      }
    },
    article() {
      const that = this
      const token = getCookie(ACCESS_TOKEN)
      const __api = token ? articleDetailWithToken : articleDetail
      Indicator.open('请稍等...')
      __api(that.id)
        .then((res) => {
          that.dataObj = res.result
          if (this.dataObj.docPath) {
          const files = this.dataObj.docPath.split(',')
          files.forEach((item, index) => {
            const file = {}
            file.name = item.substring(item.lastIndexOf('/') + 1)
            file.url = item
            this.file.push(file)
          })
        }
        })
        .catch((err) => {
          that.requestFailed(err)
        })
        .finally(() => {
          Indicator.close()
        })
    },
    // 弹窗提示
    showError() {
      const instance = Toast({
        message: this.errText,
        position: 'middle',
        duration: 5000
      })
      setTimeout(() => {
        instance.close()
      }, 3500)
    },
    // 错误请求
    requestFailed(err) {
      console.error({
        message: '错误',
        description: ((err.response || {}).data || {}).message || '请求出现错误，请稍后再试',
        duration: 4
      })
    },
    backClick() {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="less" scoped>
.infotDetai {
  margin-top: 10px;
  padding: 0 15px;

  .title {
    color: #333;
    font-size: 16px;
    margin-bottom: 16px;
    font-weight: bold;
  }

  .content {
    margin-bottom: 28px;

    .time {
      color: #999;
      font-size: 12px;
    }

    img {
      float: right;
    }
  }

  .edit {
    margin-bottom: 30px;
    width: 100%;

    ::v-deep img {
      max-width: 100% !important;
      width: auto !important;
      height: auto !important;
    }

    ::v-deep p:has(> img) {
      text-indent: 0 !important;
      margin-left: 0 !important;
      padding-left: 0 !important;
    }
  }
}
</style>
